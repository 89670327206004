var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('user.profile.title'),expression:"'user.profile.title'"}],staticClass:"text-h5"}),_c('span',{directives:[{name:"t",rawName:"v-t",value:('user.profile.subtitle'),expression:"'user.profile.subtitle'"}],staticClass:"cgwng-subheading"})])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"displayName","label":_vm.$t('displayName'),"error-messages":_vm.validationErrors(
              'profile.displayName',
              {
                required: 'required.name'
              })},on:{"blur":_vm.$v.profile.displayName.$touch},model:{value:(_vm.profile.displayName),callback:function ($$v) {_vm.$set(_vm.profile, "displayName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profile.displayName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"spellcheck":"false","name":"emailAddress","label":_vm.$t('email'),"error-messages":_vm.validationErrors(
              'profile.emailAddress',
              {
                required: 'required.email',
                email: 'general.invalid.email'
              })},on:{"blur":_vm.$v.profile.emailAddress.$touch},model:{value:(_vm.profile.emailAddress),callback:function ($$v) {_vm.$set(_vm.profile, "emailAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profile.emailAddress"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"required",attrs:{"label":_vm.$t('preferredLanguage'),"items":_vm.preferredLanguageItems,"error-messages":_vm.validationErrors(
              'profile.preferredLanguage',
              {
                required: 'required.preferredLanguage'
              })},on:{"blur":_vm.$v.profile.preferredLanguage.$touch},model:{value:(_vm.profile.preferredLanguage),callback:function ($$v) {_vm.$set(_vm.profile, "preferredLanguage", $$v)},expression:"profile.preferredLanguage"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"id":"email","type":"text","name":"ffWorkaroundEmail"}}),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"password","type":"password","name":"ffWorkaroundPassoword"}}),_c('v-switch',{attrs:{"label":_vm.$t('changePassword')},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}}),(_vm.changePassword)?_c('v-text-field',{attrs:{"autocomplete":"new-password","name":"newPassword","spellcheck":"false","label":_vm.$t('user.profile.newPassword'),"append-icon":_vm.isNewPasswordHidden ? 'visibility' : 'visibility_off',"type":_vm.isNewPasswordHidden ? 'password' : 'text'},on:{"click:append":function($event){_vm.isNewPasswordHidden = !_vm.isNewPasswordHidden}},model:{value:(_vm.profile.newPassword),callback:function ($$v) {_vm.$set(_vm.profile, "newPassword", $$v)},expression:"profile.newPassword"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.totpManageable)?_c('v-switch',{attrs:{"label":_vm.$t('totp.enable')},model:{value:(_vm.totpActive),callback:function ($$v) {_vm.totpActive=$$v},expression:"totpActive"}}):_vm._e(),(_vm.isCurrentPasswordRequired)?_c('v-text-field',{staticClass:"required",attrs:{"spellcheck":"false","name":"currentPassword","label":_vm.$t('user.profile.currentPassword'),"hint":_vm.$t ('confirmPassword'),"persistent-hint":"","append-icon":_vm.isCurrentPasswordHidden ? 'visibility' : 'visibility_off',"type":_vm.isCurrentPasswordHidden ? 'password' : 'text',"error-messages":_vm.validationErrors(
              'profile.currentPassword',
              {
                required: 'required.currentPassword'
              })},on:{"click:append":function($event){_vm.isCurrentPasswordHidden = !_vm.isCurrentPasswordHidden},"blur":_vm.$v.profile.currentPassword.$touch},model:{value:(_vm.profile.currentPassword),callback:function ($$v) {_vm.$set(_vm.profile, "currentPassword", $$v)},expression:"profile.currentPassword"}}):_vm._e()],1),(_vm.hasSavedTotpSecret && _vm.totpActive)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.clearTotpCache}},[_vm._v(" "+_vm._s(_vm.$t('user.profile.clearTotp'))+" ")])],1):_vm._e()],1)],1),_c('v-card-actions',[(_vm.canSeeHint)?_c('i18n',{attrs:{"path":"lookingForClient"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('router-link',{directives:[{name:"t",rawName:"v-t",value:('here'),expression:"'here'"}],attrs:{"to":{name:'client.profile'}}})]},proxy:true}],null,false,2198461831)}):_vm._e(),_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.cancel'),expression:"'general.button.cancel'"}],attrs:{"text":""},on:{"click":_vm.onCancel}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.save'),expression:"'general.button.save'"}],attrs:{"color":"primary","type":"submit"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }