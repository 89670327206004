<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <v-col cols="12">
      <profile-edit
        @unsavedChanges="setUnsavedChanges"
        @success="onSuccess"
        @totpInit="totpInit"
        @cancel="back"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import ProfileEdit from './components/ProfileEdit'

  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import {mapMutations} from 'vuex'

  export default {
    name: 'Profile',

    components: {
      BaseLayout,
      ProfileEdit
    },

    mixins: [unsavedChangesHelper],

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      totpInit () {
        this.$router.push ({name: 'totp.init'})
      },

      onSuccess (data) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('profileSuccess'),
          objects: [{
            name: 'profile',
            link: {
              name: 'user.profile'
            }
          }]
        })
        this.back ()
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
