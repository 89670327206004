<messages>["../UserCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-card>
    <form
      novalidate
      @submit.prevent="save">
      <v-card-title primary-title>
        <div>
          <div
            v-t="'user.profile.title'"
            class="text-h5"/>
          <span
            v-t="'user.profile.subtitle'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="profile.displayName"
              class="required"
              name="displayName"
              :label="$t('displayName')"
              :error-messages="validationErrors(
                'profile.displayName',
                {
                  required: 'required.name'
                })"
              @blur="$v.profile.displayName.$touch"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model.trim="profile.emailAddress"
              spellcheck="false"
              class="required"
              name="emailAddress"
              :label="$t('email')"
              :error-messages="validationErrors(
                'profile.emailAddress',
                {
                  required: 'required.email',
                  email: 'general.invalid.email'
                })"
              @blur="$v.profile.emailAddress.$touch"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="profile.preferredLanguage"
              class="required"
              :label="$t('preferredLanguage')"
              :items="preferredLanguageItems"
              :error-messages="validationErrors(
                'profile.preferredLanguage',
                {
                  required: 'required.preferredLanguage'
                })"
              @blur="$v.profile.preferredLanguage.$touch"/>
          </v-col>
          <v-col cols="12">
            <!--
              these fields are a workaround to prevent autocompletion
              of the field (GWNG-863/GWNG-416)
            -->
            <input id="email" style="display:none" type="text" name="ffWorkaroundEmail">
            <input id="password" style="display:none" type="password" name="ffWorkaroundPassoword">
            <v-switch
              v-model="changePassword"
              :label="$t('changePassword')"/>
            <v-text-field
              v-if="changePassword"
              v-model="profile.newPassword"
              autocomplete="new-password"
              name="newPassword"
              spellcheck="false"
              :label="$t('user.profile.newPassword')"
              :append-icon="isNewPasswordHidden ? 'visibility' : 'visibility_off'"
              :type="isNewPasswordHidden ? 'password' : 'text'"
              @click:append="isNewPasswordHidden = !isNewPasswordHidden"/>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-if="totpManageable"
              v-model="totpActive"
              :label="$t('totp.enable')"/>
            <v-text-field
              v-if="isCurrentPasswordRequired"
              v-model="profile.currentPassword"
              spellcheck="false"
              class="required"
              name="currentPassword"
              :label="$t('user.profile.currentPassword')"
              :hint="$t ('confirmPassword')"
              persistent-hint
              :append-icon="isCurrentPasswordHidden ? 'visibility' : 'visibility_off'"
              :type="isCurrentPasswordHidden ? 'password' : 'text'"
              :error-messages="validationErrors(
                'profile.currentPassword',
                {
                  required: 'required.currentPassword'
                })"
              @click:append="isCurrentPasswordHidden = !isCurrentPasswordHidden"
              @blur="$v.profile.currentPassword.$touch"/>
          </v-col>
          <v-col v-if="hasSavedTotpSecret && totpActive" cols="12">
            <v-btn text @click="clearTotpCache">
              {{ $t('user.profile.clearTotp') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <i18n v-if="canSeeHint" path="lookingForClient">
          <template #link>
            <router-link
              v-t="'here'"
              :to="{name:'client.profile'}"/>
          </template>
        </i18n>
        <v-spacer/>
        <v-btn
          v-t="'general.button.cancel'"
          text
          @click="onCancel"/>

        <v-btn
          v-t="'general.button.save'"
          color="primary"
          type="submit"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapMutations, mapActions, mapGetters} from 'vuex'
  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {email} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import {INVALID_VALUE_ERROR_CODE, PASSWORD_PROPERTY} from '@/app/services/errortranslator'
  import {TOTP_SECRET_KEY} from '@/app/store/modules/auth.js'

  export default {
    name: 'ProfileEdit',

    mixins: [validationMixins],

    data: () => ({
      profile: {
        displayName: '',
        emailAddress: '',
        currentPassword: '',
        newPassword: '',
        preferredLanguage: ''
      },
      originalEmailAddress: '',
      totpManageable: false,
      originalTotpActive: undefined,
      totpActive: undefined,
      preferredLanguageKeys: ['de', 'en'],
      changePassword: false,
      isCurrentPasswordHidden: true,
      isNewPasswordHidden: true,
      changes: 0,
      hasSavedTotpSecret: false
    }),

    validations: {
      profile: {
        displayName: {required},
        emailAddress: {required, email},
        preferredLanguage: {required},
        currentPassword: {
          required: requiredIf (function () {
            return this.isCurrentPasswordRequired
          })
        }
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayManageAllEntities',
        'mayManageOwnClient',
        'hasClient'
      ]),

      canSeeHint () {
        return this.hasClient &&
          (this.mayManageAllEntities || this.mayManageOwnClient)
      },

      preferredLanguageItems () {
        return this.preferredLanguageKeys
          .map (k => ({
            text: this.$t (`preferredLanguages.${k}`),
            value: k
          }))
      },

      isEmailAddressChanged () {
        return this.profile.emailAddress !== this.originalEmailAddress
      },

      isTotpStateChanged () {
        return this.totpActive !== this.originalTotpActive
      },

      isCurrentPasswordRequired () {
        return (this.changePassword && this.profile.newPassword) ||
          this.isEmailAddressChanged || this.isTotpStateChanged
      }
    },

    watch: {
      profile: {
        handler () {
          if (this.changes > 0) {
            this.$emit ('unsavedChanges', true)
          }

          this.changes++
        },
        deep: true
      }
    },

    created () {
      this.loadUserProfile ()

      const key = localStorage.getItem (TOTP_SECRET_KEY)
      this.hasSavedTotpSecret = key && key !== ''
    },

    methods: {
      ...mapActions ('request', [
        'fetchData'
      ]),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      save () {
        this.$v.$touch ()
        if (!this.$v.$invalid) {
          const password = this.changePassword
            ? {
              password: this.profile.newPassword
            }
            : undefined
          this.fetchData ({
            op: 'user/profile/update',
            params: {
              password: this.isCurrentPasswordRequired
                ? this.profile.currentPassword
                : null,

              totpActive: this.totpManageable
                ? this.totpActive
                : null,

              profile: {
                displayName: this.profile.displayName,
                emailAddress: this.profile.emailAddress,
                ...password,
                preferredLanguage: this.profile.preferredLanguage
              }
            },
            cb: data => {
              this.displaySuccessMessage (this.$t ('user.profile.saved'))
              this.$emit ('unsavedChanges', false)

              if (data.totpActive) {
                this.$emit ('totpInit')
              } else {
                this.$emit ('success')
              }
            },
            cbError: (data, next) => {
              if (data.result.find (
                e => e?.code === INVALID_VALUE_ERROR_CODE &&
                  e?.params?.property === PASSWORD_PROPERTY
              )) {
                this.handleInvalidPassword ()
              } else {
                next (data)
              }
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      loadUserProfile () {
        return this.fetchData ({
          op: 'user/profile/load',
          cb: data => {
            this.profile = data.profileData.profile
            this.totpManageable = data.profileData.totpManageable
            this.totpActive = data.profileData.totpActive
            this.originalTotpActive = this.totpActive
            this.originalEmailAddress = this.profile.emailAddress
          }
        })
      },

      handleInvalidPassword () {
        this.$store.commit (
          'notification/setError',
          this.$t ('invalid.currentPassword'),
          {root: true}
        )
      },

      onCancel () {
        this.$emit ('unsavedChanges', false)
        this.$emit ('cancel')
      },

      clearTotpCache () {
        localStorage.removeItem (TOTP_SECRET_KEY)
        this.hasSavedTotpSecret = false
      }
    }
  }
</script>
